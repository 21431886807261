import React from "react";
import { getDEURL, isLocalhost, redirect } from "../../utils/utils";

export default () => {
  if (isLocalhost()) {
    return <div>Redirect not working on localhost</div>;
  }
  redirect(getDEURL());
  return null;
};
